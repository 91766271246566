import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import SecureLS from 'secure-ls';
import { useAuth } from './components/authentication/AuthContext';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
// pages
import LoginDialog from './components/authentication/LoginDialog';
import LoginPageAdmin from './pages/AuthPages/LoginPageAdmin';
import AdminDashboard from './pages/AdminPages/AdminHome';
import AdminApproval from './pages/AdminPages/AdminApproval';
import AdminStores from './pages/AdminPages/AdminStores';
import AdminKYC from './pages/AdminPages/AdminKYC';
import AdminKYCApproval from './pages/AdminPages/AdminKYCApproval';
import AdminBanner from './pages/AdminPages/AdminBanner';
import AdminCreation from './pages/AdminPages/AdminCreation';
import AdminSignUp from './pages/AdminPages/AdminSignUp';
import AdminWallet from './pages/AdminPages/AdminWallet';
import AdminAccounts from './pages/AdminPages/AdminAccounts';
import AdminDealerAccount from './pages/AdminPages/AdminDealerAccount';
import Page404 from './pages/Page404';

// ----------------------------------------------------------------------

const ls = new SecureLS({ encodingType: 'aes' });

const checkTokenValidity = async () => {
  const token = ls.get('token');
  if (!token) {
    return { isValid: false, reason: 'no_token' };
  }

  try {
    const decoded = jwtDecode(token);
    if (decoded.exp * 1000 < Date.now()) {
      return { isValid: false, reason: 'expired' };
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/auth/verify-token`,
      { token },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return { isValid: response.data.isValid, reason: 'valid' };
  } catch (error) {
    console.error('Token validation error:', error);
    if (error.response) {
      return { isValid: false, reason: error.response.data.message };
    }
    return { isValid: false, reason: 'error' };
  }
};

export default function AdminRouter() {
  const location = useLocation();
  const isLoggedIn = ls.get('token');
  const user = ls.get('user');
  const role = user ? user.role : null;
  const subrole = user ? user.subrole : null;
  const [isTokenValid, setIsTokenValid] = useState(true);
  const {
    isAuthenticated,
    newLoginDialogOpen,
    tokenExpiredDialogOpen,
    setNewLoginDialogOpen,
    setTokenExpiredDialogOpen,
  } = useAuth();

  useEffect(() => {
    let intervalId;

    if (!newLoginDialogOpen && !tokenExpiredDialogOpen) {
      intervalId = setInterval(async () => {
        const { isValid, reason } = await checkTokenValidity();
        setIsTokenValid(isValid);

        if (!isValid && location.pathname.startsWith('/dashboard')) {
          if (reason === 'Token is invalid due to new login.') {
            setNewLoginDialogOpen(true);
            setTokenExpiredDialogOpen(false);
          } else if (reason === 'Token is expired.') {
            setNewLoginDialogOpen(false);
            setTokenExpiredDialogOpen(true);
          } else {
            setNewLoginDialogOpen(false);
            setTokenExpiredDialogOpen(false);
          }
        } else {
          setNewLoginDialogOpen(false);
          setTokenExpiredDialogOpen(false);
        }
      }, 60000);
    }

    return () => clearInterval(intervalId);
  }, [location.pathname, newLoginDialogOpen, tokenExpiredDialogOpen]);

  const handleCloseDialog = () => {
    setNewLoginDialogOpen(false);
    setTokenExpiredDialogOpen(false);
  };

  const routes = useRoutes([
    {
      path: '',
      element: isLoggedIn ? <Navigate to="/dashboard/admin" /> : <LoginPageAdmin />,
    },
    {
      path: '/dashboard/admin',
      element: isLoggedIn && role === 'admin' ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { element: <Navigate to="/dashboard/admin/home" />, index: true },
        { path: 'home', element: <AdminDashboard /> },
        { path: 'storeapproval', element: <AdminStores /> },
        { path: 'storeapproval/:storeId', element: <AdminApproval /> },
        { path: 'kycapproval', element: <AdminKYC /> },
        { path: 'kycapprove/:storeId', element: <AdminKYCApproval /> },
        { path: 'banner', element: <AdminBanner /> },
        { path: 'create', element: subrole === 'crm' ? <Navigate to="/404" /> : <AdminCreation /> },
        { path: 'dealers', element: <AdminDealerAccount /> },
        { path: 'accounts', element: subrole === 'crm' ? <Navigate to="/404" /> : <AdminAccounts /> },
        { path: 'wallet/manage-ca', element: <AdminWallet /> },
      ],
    },
    {
      path: 'admin-signup',
      element: <AdminSignUp />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return (
    <>
      <LoginDialog
        open={newLoginDialogOpen}
        onClose={() => handleCloseDialog('newLogin')}
        dialogType="newLogin"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <LoginDialog
        open={tokenExpiredDialogOpen}
        onClose={() => handleCloseDialog('tokenExpired')}
        dialogType="tokenExpired"
        disableBackdropClick
        disableEscapeKeyDown
      />
      {routes}
    </>
  );
}

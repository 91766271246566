export const excludedPaths = [
    '/dashboard',
    '/login',
    '/signup',
    '/forgotpassword',
    '/reset-password',
    '/verify',
    '/bills',
    '/voucher',
    '/gift',
    '/topup',
    '/transactions',
    '/404',
    '/admin',
  ];